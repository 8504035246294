import { styled } from '@mui/material/styles';
import { Colors } from '../../utils/colors';
import theme from '../../utils/theme';
import '../../assets/fonts/fonts.css';

// HEADER
export const HeaderContainer = styled('div')({
  width: '100%',
  height: '55px',
  backgroundColor: Colors.PRIMARY_CONTRAST_TEXT,
  display: 'flex',
  padding: '10px 15px',
});

export const HeaderIconWrapper = styled('div')({
  width: '1024px',
  margin: 'auto',

  [theme.breakpoints.down('desktop')]: {
    width: '100%',
  },
});

// SUBHEADER
export const SubHeaderContainer = styled('div')({
  width: '100%',
  borderBottom: `1px solid ${Colors.NEUTRAL_N9}`,

  [theme.breakpoints.down('tablet')]: {
    borderBottom: 'unset'
  },
});

export const SvgWrapper = styled('div')({
  display: 'flex',
  color: Colors.PRIMARY,
  width: '1024px',
  margin: 'auto',
  padding: '12px 0 10px',
  
  [theme.breakpoints.between('tablet', 'desktop')]: {
    width: '100%',
    padding: '12px 17px 10px'
  },

  [theme.breakpoints.down('tablet')]: {
    width: '100%',
    padding: '4px 15px'
  },
});