import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { Colors } from '../../utils/colors';
import theme from '../../utils/theme';
import '../../assets/fonts/fonts.css';

export const IntroWrapper = styled('div')({
  [theme.breakpoints.between('tablet', 'desktop')]: {
    width: '370px',
  },
});

export const Title = styled(Typography)({
  width: 'inherit',
  color: Colors.BLACK_NEUTRAL,
  fontFamily: 'LatoBold',
  fontWeight: '700',
  fontSize: '38px',
  lineHeight: '46px',
  margin: '0 0 10px',

  [theme.breakpoints.between('tablet', 'desktop')]: {
    width: '370px',
    fontSize: '30px',
    lineHeight: '36px',
  },

  [theme.breakpoints.down('tablet')]: {
    width: '345px',
    fontSize: '30px',
    lineHeight: '36px',
  },
});

export const Separator = styled('div')<{ bgColor: string }>(({ bgColor }) => ({
  backgroundColor: `${bgColor}`,
  width: '60px',
  height: '5px',
}));

export const Description = styled(Typography)({
  margin: '10px 0 0',
  color: Colors.BLACK_NEUTRAL,
  fontSize: '18px',
  lineHeight: '25px',

  [theme.breakpoints.between('tablet', 'desktop')]: {
    margin: '30px 0 0'
  },

  [theme.breakpoints.down('tablet')]: {
    fontSize: '16px',
    lineHeight: '22px'
  },
});