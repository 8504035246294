import { styled } from '@mui/material/styles';
import { Typography, IconButton } from '@mui/material';
import { Colors } from '../../utils/colors';
import theme from '../../utils/theme';
import '../../assets/fonts/fonts.css';

// DEVICE LIST 
export const DeviceListContainer = styled('div')({
  maxWidth: '465px',
  display: 'grid',
  columnGap: '15px',
  rowGap: '15px',
  gridTemplateColumns: 'repeat(4, 105px)',   // TODO: make responsive to number of device items
  gridTemplateRows: 'repeat(3, 151px)',

  [theme.breakpoints.down('desktop')]: {
    gridTemplateColumns: 'repeat(3, 105px)',   // TODO: make responsive to number of device items
    gridTemplateRows: 'repeat(4, 151px)',
  }
});

export const DeviceItem = styled('div')<{ nonZero: boolean }>(({ nonZero }) => ({
  width: '105px',
  height: '151px',
  border: `1px solid ${nonZero ? Colors.PRIMARY : Colors.NEUTRAL_N8}`,
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
}));

interface IDeviceType { fill: string }
export const DeviceType = styled('div')<IDeviceType>(({ fill }) => ({
  height: '116px',
  padding: '10px 0',
  display: 'flex',
  flexDirection: 'column',

  '&> div': { 
    display: 'flex',
    width: '70px',
    height: '70px',
    margin: '0 auto'
  },

  '&> div >svg >path': { 
    fill: `${fill}`
  },
}));

export const IconLabel = styled(Typography)({
  fontSize: '14px',
  lineHeight: '22px',
  textAlign: 'center',
  color: Colors.NEUTRAL_N5,
  margin: '5px 0 0'
});

export const Controls = styled('div')<{ nonZero: boolean }>(({ nonZero }) => ({
  width: '100%',
  height: 'calc(100% - 116px)',
  display: 'flex',
  flexDirection: 'row',
  borderTop: `1px solid ${nonZero ? Colors.PRIMARY : Colors.NEUTRAL_N8}`,
  borderRadius: '0 0 8px 8px',
  backgroundColor: nonZero ? Colors.PRIMARY : Colors.WHITE,

  [theme.breakpoints.between('tablet', 'desktop')]: {
    height: 'calc(100% - 96px)',
  }
}));

export const Count = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'nonZero'
})<{ nonZero: boolean }>(({ nonZero }) => ({
  fontSize: '18px',
  lineHeight: '25px',
  fontWeight: '700',
  color: nonZero ? Colors.WHITE : Colors.NEUTRAL_N2,
  textAlign: 'center',
  width: '25px', // TODO: add allowance if 3-digit number
  height: 'fit-content',
  margin: 'auto'
}));

export const ActionButton = styled(IconButton,  {
  shouldForwardProp: (prop) => prop !== 'nonZero' && prop !== 'svgType'
})<{ svgType: string,  nonZero: boolean }>(({ svgType, nonZero }) => ({
  backgroundColor: 'inherit',
  width: '39px',
  borderRadius: svgType === 'add' ? '0 0 8px 0' : '0 0 0 8px',
  transitionDuration: 'unset',

  '&> svg': {
    height: svgType === 'add' ? '21px' : '24px',
    width: svgType === 'add' ? '21px' : '24px',
    color: nonZero ? Colors.WHITE : Colors.NEUTRAL_N2
  },
}));

