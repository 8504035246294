import React from 'react';
import { FooterDetails, LinkText } from './FooterStyles';
import { TEXT_CONTENT } from '../../utils/textContent';
import useAnalytics from '../../hooks/useAnalytics';
import { ANALYTICS_EVENTS } from '../../lib/analytics';

const Footer: React.FC = () => {
  const { addEvent } = useAnalytics();

  const updateGtm = () => {
    addEvent(ANALYTICS_EVENTS.FOOTER_LINK_CLICK, {});
  };

  return (
    <FooterDetails>
      {TEXT_CONTENT.FOOTER_DETAILS}
      <LinkText href={TEXT_CONTENT.STARHUB_LINK_REF} target='_blank' onClick={updateGtm}>
        {TEXT_CONTENT.STARHUB_LINK_DISPLAY}
      </LinkText>
    </FooterDetails>
  );
}

export default Footer;
