import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { Colors } from '../../utils/colors';
import theme from '../../utils/theme';
import '../../assets/fonts/fonts.css';

export const CostWrapper = styled('div')({
  width: 'inherit',
  height: 'fit-content',
  margin: '30px 0 0',

  [theme.breakpoints.between('tablet', 'desktop')]: {
    margin: '60px 0 0',
  },
});

export const CostContainer = styled('div')({
  width: 'inherit',
  height: 'fit-content',
  padding: '15px',
  borderRadius: '8px',
  backgroundColor: Colors.SECONDARY_S4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const CostLabel = styled(Typography)({
  width: '100%',
  fontSize: '18px', 
  lineHeight: '25px',
  textAlign: 'center',
  color: Colors.WHITE,

  [theme.breakpoints.down('tablet')]: {
    fontWeight: '400',
    fontSize: '16px', 
    lineHeight: '22px',
  },
});

export const CostValue = styled(Typography)({
  width: '100%',
  fontWeight: '700',
  fontSize: '38px', 
  lineHeight: '46px',
  textAlign: 'center',
  color: Colors.WHITE,
  overflowWrap: 'break-word',
  marginTop: '10px',

  [theme.breakpoints.down('tablet')]: {
    fontSize: '30px', 
    lineHeight: '36px',
  },
});

export const CostDetails = styled(Typography)({
  width: '100%',
  marginTop: '5px',
  color: Colors.NEUTRAL_N3,
  fontSize: '14px',
  lineHeight: '22px',
});
