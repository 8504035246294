import React from 'react';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';
import { Colors } from '../../utils/colors';
import { IDeviceInfoMap } from '../../types';
import SvgInline from '../icons/SvgInline'; 

import {
  DeviceListContainer,
  DeviceItem,
  DeviceType,
  IconLabel,
  Controls,
  Count,
  ActionButton
} from './DeviceListStyles';

interface IDeviceList {
  deviceList: string[],
  deviceInfoMap: IDeviceInfoMap,
  incrementDeviceCnt: (type: string) => void,
  decrementDeviceCnt: (type: string) => void,
}

const DeviceList: React.FC<IDeviceList> = ({ 
  deviceList,
  deviceInfoMap,
  incrementDeviceCnt,
  decrementDeviceCnt
}) => {
  return (
    <DeviceListContainer>
      {deviceList.map((type, dIdx) => {
        // TODO: refactor use of nonZero props, transfer styling to theme
        const nonZero: boolean = deviceInfoMap[type].quantity > 0;
        return (
          <DeviceItem key={dIdx} nonZero={nonZero}>
            <DeviceType fill={nonZero ? Colors.PRIMARY_CONTRAST_TEXT : Colors.NEUTRAL_N4}>
              <SvgInline url={deviceInfoMap[type].icon} />
              <IconLabel>{type}</IconLabel>
            </DeviceType>
            <Controls nonZero={nonZero}>
              <ActionButton
                svgType='remove'
                nonZero={nonZero}
                onClick={() => decrementDeviceCnt(type)}
                disableFocusRipple
                disableRipple
              >
                <RemoveIcon />
              </ActionButton>
              <Count nonZero={nonZero}>{deviceInfoMap[type].quantity}</Count>
              <ActionButton
                svgType='add'
                nonZero={nonZero}
                onClick={() => incrementDeviceCnt(type)}
                disableFocusRipple
                disableRipple
              >
                <AddIcon />
              </ActionButton>
            </Controls>
          </DeviceItem>
        );
      })}
    </DeviceListContainer>
  );
}

export default DeviceList;

