import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { Colors } from '../../utils/colors';
import theme from '../../utils/theme';
import '../../assets/fonts/fonts.css';

export const FooterDetails = styled(Typography)({
  width: '1024px',
  height: '66px',
  fontSize: '14px',
  lineHeight: '22px',
  color: Colors.NEUTRAL_N3,
  margin: '15px auto 5px',

  [theme.breakpoints.between('tablet', 'desktop')]: {
    width: '800px',
    height: '88px',
    color: Colors.NEUTRAL_N3,
    margin: '15px auto 5px',
  },

  [theme.breakpoints.down('tablet')]: {
    width: '345px',
    height: '105px',
    fontSize: '12px',
    lineHeight: '15px',
    margin: '10px auto 20px',
  },
});

export const LinkText = styled('a')({
  color: Colors.PRIMARY,
  fontSize: '14px',
  lineHeight: '22px',

  [theme.breakpoints.down('tablet')]: {
    fontSize: '12px',
    lineHeight: '15px',
  },
});
