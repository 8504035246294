import { styled } from '@mui/material/styles';
import { Colors } from '../utils/colors';
import theme from '../utils/theme';
import '../assets/fonts/fonts.css';

export const ParentContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: Colors.WHITE,
  height: 'fit-content',
  overflowY: 'auto'
});

// CONTENT WRAPPERS
export const MainContent = styled('div')({
  width: '100%'
});

export const ContentWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  margin: '30px auto',
  width: '1024px',
  height: 'fit-content',

  [theme.breakpoints.between('tablet', 'desktop')]: {
    margin: '10px auto',
    width: '800px',
  },

  [theme.breakpoints.down('tablet')]: {
    margin: '10px auto',
    width: '345px',
    flexDirection: 'column',
    justifyContent: 'unset',
  },
});

export const LeftColWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '490px',

  [theme.breakpoints.between('tablet', 'desktop')]: {
    width: '400px',
  },

  [theme.breakpoints.down('tablet')]: {
    width: 'inherit'
  }
});

export const RightColWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.down('tablet')]: {
    margin: '15px 0 0'
  }
});

