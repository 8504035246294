import { ITextContent } from '../types';

export const TEXT_CONTENT: ITextContent = {
  SMART_HOME: 'SmartSupport Home',
  TITLE: 'Invest in protection, not extended warranties',
  SUBTITLE_1: 'Your total spend on home tech could be substantial and would be worth having a comprehensive plan that covers all your new and existing devices.',
  SUBTITLE_2: 'Do the math using our device investment calculator now.',
  COST_LABEL: 'Estimated spend on your home devices*',
  COST_SUBDETAILS: '*The value per device is an estimate, based on the median prices of the devices available for purchase online in 2022.',
  PROTECTION_HEADER: 'Get the protection your home devices deserve',
  PROTECTION_DESC: 'Multiple extended warranties can be costly and limited.',
  FOOTER_DETAILS: '$0.68 a day refers to the Device Cover which provides you with up to $5,000 coverage per rolling or $20.36/month (inclusive of GST) Please refer to the Coverage Summary and SmartSupport Home Terms and Conditions apply. For more details, visit ',
  STARHUB_LINK_DISPLAY: 'www.starhub.com/smartsupporthome',
  STARHUB_LINK_REF: 'https://www.starhub.com/smartsupporthome'
};