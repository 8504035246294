import { IColors } from '../types';

export const Colors: IColors = {
  PRIMARY: '#0F6B30',
  SECONDARY_S4: '#250E62',
  PRIMARY_CONTRAST_TEXT: '#1ED760',
  BLACK: '#000000',
  BLACK_NEUTRAL: '#3D3D3B',
  WHITE: '#FFFFFF',
  NEUTRAL_N3: '#575757',
  NEUTRAL_N4: '#70706E',
  NEUTRAL_N5: '#878787',
  NEUTRAL_N8: '#C7C7C5',
  NEUTRAL_N9: '#D9D9D9',
  ACCENT_A2: '#685BC7'
}