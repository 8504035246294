const init = () => {
    window.dataLayer = window.dataLayer || [];

    // console.info('Analytics was initialized', window.dataLayer);
};

export enum ANALYTICS_EVENTS {
    HOME_PAGE = 'Home Page',
    ADD_DEVICE = 'Add Device',
    SUBTRACT_DEVICE = 'Subtract Device',
    FOOTER_LINK_CLICK = 'Footer link click'
}

export enum ACTION_TYPES {
    ADD = 'Add',
    SUBTRACT = 'Subtract'
}

const eventNamePrefix = 'HomePlus_Calculator_DL_Event';

interface DeviceEventProps {
   device?: string;
   action?: string;
}

const event = (
    event: string,
    data: DeviceEventProps & Record<string, any>
) => {
    if (window?.dataLayer?.length >= 0) {
        const newEvent = {
            event: `${eventNamePrefix} ${event}`,
            Timestamp: new Date().getTime(),
            ...data,
        };

        window.dataLayer.push(newEvent);
        // console.info('GTM event added', newEvent);
    } else {
        console.error('window.dataLayer not initizalied.');
    }
};

const analytics = { init, event };

export default analytics;