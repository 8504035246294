import React from 'react';
import { useMediaQuery } from '@mui/material';
import { getValuePerDevice } from '../../utils/helpers';
import { TEXT_CONTENT } from '../../utils/textContent';
import { Colors } from '../../utils/colors';
import theme from '../../utils/theme';

import {
  ProtectionContainer,
  ProtectionHeaderWrapper,
  ProtectionHeader,
  Separator,
  ProtectionDescription,
  ValuePerDay,
  ValuePerDevice
} from './ProtectionDetailsStyles';
import { IDeviceInfoMap } from '../../types';

interface IProtectionDetails { totalPrice: number; deviceInfoMap: IDeviceInfoMap }

const ProtectionDetails: React.FC<IProtectionDetails> = ({ totalPrice, deviceInfoMap }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));
  
  const valuePerDevice = getValuePerDevice(deviceInfoMap);
  const nonZero: boolean = totalPrice > 0;

  return (
    <ProtectionContainer nonZero={nonZero}>
      <ProtectionHeaderWrapper>
        <ProtectionHeader>
          {TEXT_CONTENT.PROTECTION_HEADER}
        </ProtectionHeader>
        <Separator bgColor={nonZero ? Colors.WHITE : Colors.SECONDARY_S4}/>  
      </ProtectionHeaderWrapper>
      <>
        {nonZero ? (
          <>
            <ValuePerDay>for just $0.68 a day</ValuePerDay>
            <ValuePerDevice>{`$${valuePerDevice} per device per day`}</ValuePerDevice>
            <ProtectionDescription nonZero={nonZero}>
              {`You only need one SmartSupport Home `}
              {isMobile && <br />}
              {`to protect your new and existing home devices​`}
            </ProtectionDescription>
          </>
        ) : (
          <ProtectionDescription nonZero={nonZero}>
            {TEXT_CONTENT.PROTECTION_DESC}
          </ProtectionDescription>
        )}
      </>
    </ProtectionContainer>
  );
}

export default ProtectionDetails;
