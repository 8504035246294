import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { Colors } from '../../utils/colors';
import theme from '../../utils/theme';
import '../../assets/fonts/fonts.css';

export const ProtectionContainer = styled('div')<{ nonZero: boolean }>(({ nonZero }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: nonZero ? '15px' : '25px',
  backgroundColor: nonZero ? Colors.ACCENT_A2 : Colors.PRIMARY_CONTRAST_TEXT,
  color: nonZero ? Colors.WHITE : Colors.SECONDARY_S4,
  
  [theme.breakpoints.between('tablet', 'desktop')]: {
    marginTop: nonZero ? '24.5px' : '50px',
    padding: '25px 15px'
  },

  [theme.breakpoints.down('tablet')]: {
    marginTop: '30px',
    padding: '25px 15px'
  },
}));

export const ProtectionHeaderWrapper = styled('div')({
  width: '615px',
  margin: '0 auto',
  
  [theme.breakpoints.down('tablet')]: {
    width: '345px',
  },
});

export const ProtectionHeader = styled(Typography)({
  width: '615px',
  fontWeight: '700',
  fontSize: '30px',
  lineHeight: '36px',
  margin: '0 auto 10px',

  [theme.breakpoints.down('tablet')]: {
    width: '345px',
    fontSize: '20px',
    lineHeight: '25px',
  },
});

export const ValuePerDay = styled(Typography)({
  width: 'inherit',
  margin: '20px auto 0',
  fontWeight: '700',
  fontSize: '38px',
  lineHeight: '46px',
  textAlign: 'center',

  [theme.breakpoints.between('tablet', 'desktop')]: {
    margin: '30px auto 0',
  },

  [theme.breakpoints.down('tablet')]: {
    margin: '30px auto 0',
  },
});

export const ValuePerDevice = styled(Typography)({
  width: 'inherit',
  margin: '5px auto 0',
  fontWeight: '500',
  fontSize: '30px',
  lineHeight: '36px',
  textAlign: 'center',
});

export const ProtectionDescription = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'nonZero'
})<{ nonZero: boolean }>(({ nonZero }) => ({
  width: 'inherit',
  margin: nonZero ? '20px auto 0' : '30px auto 0',
  fontSize: '20px',
  lineHeight: '25px',
  textAlign: 'center',

  [theme.breakpoints.between('tablet', 'desktop')]: {
    margin: '30px auto 0'
  },
  
  [theme.breakpoints.down('tablet')]: {
    width: '345px',
    fontSize: '16px',
    lineHeight: '22px',
    textAlign: nonZero ? 'center' : 'left',
    margin: nonZero ? '30px auto 0' : '40px auto 0'
  },
}));

export const Separator = styled('div')<{ bgColor: string }>(({ bgColor }) => ({
  backgroundColor: `${bgColor}`,
  width: '60px',
  height: '5px',
}));
