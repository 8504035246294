import React, { useEffect } from 'react';
import { Calculator } from './components';
import theme from './utils/theme';
import { ThemeProvider } from '@mui/material/styles';
import TagManager from 'react-gtm-module';
import { 
  ParentContainer,
  MainContent
} from './components/CalculatorStyles';
import analytics, { ANALYTICS_EVENTS } from './lib/analytics';
import useAnalytics from './hooks/useAnalytics';

const App: React.FC = () => {
  const { addEvent } = useAnalytics();

  useEffect(() => {
    const gtmId = process.env.REACT_APP_GTM_ID;

    if (gtmId) {
      const tagManagerArgs = { gtmId };
      
      TagManager.initialize(tagManagerArgs);

      analytics.init();

      if (window.dataLayer) {
        addEvent(ANALYTICS_EVENTS.HOME_PAGE, {});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ParentContainer>
        <MainContent>
          <Calculator />
        </MainContent>
      </ParentContainer>
    </ThemeProvider>
  );
}

export default App;
