import React from 'react';
import { Colors } from '../../utils/colors';
import { TEXT_CONTENT } from '../../utils/textContent';

import {
  Title,
  Separator,
  Description,
  IntroWrapper
} from './IntroStyles';

const Intro: React.FC = () => {
  return (
    <IntroWrapper>
      <Title>  
        {`Invest in protection, `}
        <br />
        {`not extended warranties`}
      </Title>
      <Separator bgColor={Colors.PRIMARY_CONTRAST_TEXT} />
      <Description>
        {TEXT_CONTENT.SUBTITLE_1}
        <br/><br/>
        {TEXT_CONTENT.SUBTITLE_2}
      </Description>
    </IntroWrapper>
  );
}

export default Intro;

