import React, { useState, useEffect } from 'react';
import { 
  Footer,
  Intro,
  CostComputation,
  DeviceList,
  ProtectionDetails,
} from '../components';
import { 
  ContentWrapper,
  LeftColWrapper,
  RightColWrapper,
} from './CalculatorStyles';

import { IDeviceInfoMap } from '../types';
import { IDevicesFields } from '../contentful/contentfulTypes';
import { contentFulclient } from '../contentful/contentfulConfig';
import useAnalytics from '../hooks/useAnalytics';
import { ACTION_TYPES, ANALYTICS_EVENTS } from '../lib/analytics';

const Calculator: React.FC = () => {
  const [deviceList, setDeviceList] = useState<string[]>([]);
  const [deviceInfoMap, setDeviceInfoMap] = useState<IDeviceInfoMap>({});
  const [totalPrice, setTotalPrice] = useState<number>(0);

  const { addEvent } = useAnalytics();

  const updateGtm = (actionType: ACTION_TYPES, deviceType: string) => {
    const gtmData = {
      device: deviceType,
      action: actionType
    };

    if (actionType === ACTION_TYPES.ADD) {
      addEvent(`${ANALYTICS_EVENTS.ADD_DEVICE} ${deviceType}`, gtmData);
    } else {
      addEvent(`${ANALYTICS_EVENTS.SUBTRACT_DEVICE} ${deviceType}`, gtmData);  
    }   
  }

  const incrementDeviceCnt = (deviceType: string) => {
    let updatedInfoMap = {...deviceInfoMap};
    updatedInfoMap[deviceType].quantity += 1;
    setDeviceInfoMap(updatedInfoMap);
    setTotalPrice(totalPrice + deviceInfoMap[deviceType].price);

    updateGtm(ACTION_TYPES.ADD, deviceType);
  }

  const decrementDeviceCnt = (deviceType: string) => {
    let updatedInfoMap = {...deviceInfoMap};
    
    if(updatedInfoMap[deviceType].quantity > 0) {  
      updatedInfoMap[deviceType].quantity -= 1;
      setDeviceInfoMap(updatedInfoMap);
      setTotalPrice(totalPrice - deviceInfoMap[deviceType].price);

      updateGtm(ACTION_TYPES.SUBTRACT, deviceType);
    }
  }

  const getDeviceInfoMapping = () => {
    contentFulclient.getEntries<IDevicesFields>({
      content_type: 'homePlusCalculatorDevices',
    })
    .then((entries) => {
      const newDeviceInfoMap = entries.items.reduce((acc, item) => {
        const fields = item.fields;
        const { icon, type, price } = fields;
        return {
          ...acc,
          [type]: {
            // icon: `https:${icon.fields.file.url}`,
            icon: `https:${icon['fields']['file']['url']}`,
            price: parseInt(price),
            quantity: 0,
          }
        }
      }, {});
      const newDeviceList = [...Object.keys(newDeviceInfoMap).sort((a, b) => a.localeCompare(b))];
      setDeviceInfoMap(newDeviceInfoMap);
      setDeviceList(newDeviceList);
    })
    .catch((err) => {
      console.error(err);
    });
  };
  
  useEffect(() => {
    getDeviceInfoMapping();
  }, []);

  return (
    <>
      <ContentWrapper>
        <LeftColWrapper>
          <Intro />
          <CostComputation totalPrice={totalPrice} />
        </LeftColWrapper>
        <RightColWrapper>
          <DeviceList
            deviceList={deviceList}
            deviceInfoMap={deviceInfoMap}
            incrementDeviceCnt={incrementDeviceCnt}
            decrementDeviceCnt={decrementDeviceCnt}
          />
        </RightColWrapper>
      </ContentWrapper>
      <ProtectionDetails 
        totalPrice={totalPrice}
        deviceInfoMap={deviceInfoMap}
      />
      <Footer />
    </>
  );
}

export default Calculator;

