import { useCallback } from 'react';

import analytics from '../lib/analytics';

const useAnalytics = () => {
    const init = useCallback(() => analytics.init(), []);
    const addEvent = useCallback(
        (
            event: string,
            data: Record<string, any> = {}
        ) => {
            analytics.event(event, {
                ...data,
            });
        },
        []
    );

    return { addEvent, init };
};

export default useAnalytics;