import { createTheme } from '@mui/material/styles';
import { Colors } from './colors';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false; // removes the `xs` breakpoint
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true; // adds the `mobile` breakpoint
    tablet: true;
    desktop: true;
  }
}

const theme = createTheme({
  breakpoints: {
    values: {
      mobile: 375,
      tablet: 834,
      desktop: 1054,
    },
  },
  palette: {
    primary: {
      main: Colors.PRIMARY_CONTRAST_TEXT,
      contrastText: Colors.WHITE,
    },
  },
  typography: {
    fontFamily: 'LatoRegular',
    fontWeightRegular: 400
  },
  spacing: 8,
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 0
        }
      }
    }
  }
});

export default theme;