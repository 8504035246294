import { IDeviceInfoMap } from "../types";

export const getPercentageVal = (totalPrice: number) => {
  const prod: number = 12 * 19.99;
  const percentage: number = prod / totalPrice;
  return Math.ceil(percentage);
};

export const getValuePerDevice = (deviceInfoMap: IDeviceInfoMap) => {
  let totalDeviceCount = 0;
  
  Object.entries(deviceInfoMap).forEach(([deviceType, deviceInfo]) => {
    totalDeviceCount += deviceInfo.quantity;
  })

  const result = totalDeviceCount !== 0 ? (0.68 / totalDeviceCount) : 0.68;

  return result.toFixed(2);
};
