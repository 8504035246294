import React from 'react';
import { TEXT_CONTENT } from '../../utils/textContent';

import {
  CostWrapper,
  CostContainer,
  CostLabel,
  CostValue,
  CostDetails
} from './CostComputationStyles';

interface ICostComputation {
  totalPrice: number
}

const CostComputation: React.FC<ICostComputation> = ({ totalPrice = 0 }) => {
  const formattedPrice = new Intl.NumberFormat('en-US').format(totalPrice);
  
  return (
    <CostWrapper>
      <CostContainer>
        <CostLabel>{TEXT_CONTENT.COST_LABEL}</CostLabel>
        <CostValue>${formattedPrice}</CostValue>
      </CostContainer>
      <CostDetails>
        {TEXT_CONTENT.COST_SUBDETAILS}
      </CostDetails>
    </CostWrapper>
  );
}

export default CostComputation;

